import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '@/store'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_CLIENTES;

class Clientes
{
	obtener_solicitudes(options) {
		return axios.get(utils.url_options(baseURL+'/solicitudes',options));
	}

	obtener_detalles_solicitudes(id) {
		return axios.get(baseURL+'/solicitudes/'+id);
	}

	actualizar_solicitud(id, payload) {
		return axios.put(baseURL+'/solicitudes/'+id, payload);
	}

	listar_usuarios(options, payload) {
		if (options && !payload)
			return axios.get(utils.url_options(baseURL+'/clientes', options));
		else
			return axios.get(baseURL+'/clientes', payload);
	}

	detalle_usuario(id) {
		return axios.get(baseURL+'/clientes/'+id);
	}

	crear_usuario(id) {
		return axios.post(baseURL+'/clientes/'+id);
	}

	asociar_credito_usuario(idUsuario, idSolicitud) {
		return axios.put(baseURL+'/clientes/'+idUsuario+'/asignar/'+idSolicitud);
	}

	usuarios_reestructurar_credito(credito_id, payload){
		return axios.put(`${baseURL}/clientes/credito/${credito_id}`, payload);
	}	

	enviar_observacion(idUsuario, payload) {
		return axios.post(baseURL+'/clientes/'+idUsuario+'/observacion', payload);
	}

	guardar_contacto(id, payload) {
		return axios.post(baseURL+'/clientes/'+id+'/contacto',payload);
	}

	modificar_contacto(id, idContacto, payload) {
		return axios.put(baseURL+'/clientes/'+id+'/contacto/'+idContacto, payload);
	}

	eliminar_contacto(id, idContacto) {
		return axios.delete(baseURL+'/clientes/'+id+'/contacto/'+idContacto);
	}

	guardar_cambios_cliente(id, payload) {
		return axios.put(baseURL+'/clientes/'+id, payload);
	}

	crear_tipo_contacto(payload) {
		return axios.post(baseURL+'/catalogos/contacto', payload);
	}

	listar_facturas(options,no_credito) {
		return axios.get(utils.url_options(baseURL+'/facturacion/'+no_credito+'/facturas',options));
	}

	reenviar_facturas(no_credito, payload) {
		return axios.post(baseURL+'/facturacion/'+no_credito+'/reenviar', payload);
	}

	listar_metodos_validacion(id) {
		return axios.get(baseURL+'/clientes/'+id+'/contacto');
	}

	/** Buscar Cliente SOLICITUDES*/

	solicitudes_buscar_cliente(filtro, valor){
		return axios.get(`${baseURL}/clientes/buscar/${filtro}/${valor}`);
	}

	crear_contacto_solicitud(id, payload) {
		return axios.post(baseURL+'/solicitudes/'+id+'/contacto', payload);
	}

	modificar_contacto_solicitud(id, idContacto, payload) {
		return axios.put(baseURL+'/solicitudes/'+id+'/contacto/'+idContacto, payload);
	}

	eliminar_contacto_solicitud(id, idContacto) {
		return axios.delete(baseURL+'/solicitudes/'+id+'/contacto/'+idContacto);
	}

	acceso_sistema(payload) {
		return axios.post(baseURL+'/accesos', payload);
	}

	listar_accesos(options) {
		return axios.get(utils.url_options(baseURL+'/accesos',options));
	}

	obtener_informacion_pago(no_credito) {
		return axios.get(baseURL+'/facturacion/'+no_credito+'/infopago');
	}

	guardar_direccion_solicitud(id, payload) {
		return axios.post(baseURL+'/solicitudes/'+id+'/direcciones', payload);
	}

	actualizar_direccion_solicitud(id, idDir, payload) {
		return axios.put(baseURL+'/solicitudes/'+id+'/direcciones/'+idDir, payload);
	}

	eliminar_direccion_solicitud(id, idDir) {
		return axios.delete(baseURL+'/solicitudes/'+id+'/direcciones/'+idDir);
	}

	guardar_direccion_cliente(id, payload) {
		return axios.post(baseURL+'/clientes/'+id+'/direcciones', payload);
	}

	actualizar_direccion_cliente(id, idDir, payload) {
		return axios.put(baseURL+'/clientes/'+id+'/direcciones/'+idDir, payload);
	}

	eliminar_direccion_cliente(id, idDir) {
		return axios.delete(baseURL+'/clientes/'+id+'/direcciones/'+idDir);
	}

	listar_actividades(options) {
		return axios.get(utils.url_options(baseURL+'/actividades',options));
	}

	catalogos_obtener(catalogo) {
		return axios.get(`${baseURL}/catalogos/obtener/${catalogo}`);
	}

	catalogos_listar(options) {
		return axios.get(utils.url_options(`${baseURL}/catalogos`,options));
	}

	catalogos_detalles(id) {
		return axios.get(`${baseURL}/catalogos/${id}/detalle`);
	}

	catalogo_crear(catalogo) {
		return axios.post(`${baseURL}/catalogos`, catalogo);
	}

	catalogo_editar(id, catalogo) {
		return axios.put(`${baseURL}/catalogos/${id}`, catalogo);
	}

	catalogo_agregar_opcion(catalogo_id, opcion) {
		return axios.post(`${baseURL}/catalogos/${catalogo_id}/opciones`, opcion);
	}

	catalogo_editar_opcion(catalogo_id, opcion) {
		return axios.put(`${baseURL}/catalogos/${catalogo_id}/opciones/${opcion.id}`, opcion);
	}

	catalogo_eliminar_opcion(catalogo_id, opcion_id) {
		return axios.delete(`${baseURL}/catalogos/${catalogo_id}/opciones/${opcion_id}`);
	}

	buscar_cliente(payload) {
		return axios.post(`${baseURL}/core/clientes/buscar`, payload);
	}

	core_catalogos(catalogo) {
		if (!catalogo)
			return axios.get(`${baseURL}/core/catalogos`);
		else
			return axios.get(`${baseURL}/core/catalogos/${catalogo}`);
	}

	eliminar_creditos_fondeadores(solicitud_id, credito_id, fondeador_id) {
		return axios.delete(`${baseURL}/solicitudes/${solicitud_id}/creditos/${credito_id}/fondeadores/${fondeador_id}`);
	}

	actualizar_credito(cliente_id, credito_id, payload) {
		return axios.put(`${baseURL}/clientes/${cliente_id}/credito/${credito_id}`, payload);
	}
	
	// TICKETS

	/** Tickets - Catálogos */
	listar_catalogos(valor) {
		return axios.get(`${baseURL}/catalogos/${valor}`);
	}
	/** Tickets - Clientes */
	obtener_informacion_cliente(id){
		return axios.get(`${baseURL}/clientes/informacion/${id}`);
	}
	/** Tickets - Contactos  */
	listar_contactos_tickets(id){
		return axios.get(`${baseURL}/clientes/${id}/contacto`);
	}	

	crear_contacto_tickets(id, payload){
		return axios.post(`${baseURL}/clientes/${id}/contacto`, payload);
	}	

	actualizar_contacto_tickets(id, idContacto, payload) {
		return axios.put(`${baseURL}/clientes/${id}/contacto/${idContacto}`, payload);
	}

	eliminar_contacto_tickets(id, idContacto){
		return axios.delete(`${baseURL}/clientes/${id}/contacto/${idContacto}`);
	}
	
	/** Tickets - Direcciones */
	listar_direcciones_tickets(id){
		return axios.get(`${baseURL}/clientes/${id}/ticket/direcciones`);
	}

	crear_direccion_tickets(id, payload){
		return axios.post(`${baseURL}/clientes/${id}/direcciones`, payload);
	}

	actuallizar_direccion_tickets(id, idDir, payload){
		return axios.put(`${baseURL}/clientes/${id}/direcciones/${idDir}`, payload);
	}

	eliminar_direccion_tickets(id, idDir){
		return axios.delete(`${baseURL}/clientes/${id}/direcciones/${idDir}`);
	}

	/** Tickets -Configuración */

	listar_configuracion(){
		return axios.get(`${baseURL}/configuracion`);
	}
	crear_configuracion(payload){
		return axios.post(`${baseURL}/configuracion`, payload);
	}
	editar_configuracion(id, payload){
		return axios.put(`${baseURL}/configuracion/${id}`, payload);
	}
	eliminar_configuracion(id){
		return axios.delete(`${baseURL}/configuracion/${id}`);
	}

	/** Tickets */
	listar_tickets(){
		return axios.get(`${baseURL}/tickets`);
	}

	listar_ticket_filtro(filtro, valor){
		return axios.get(`${baseURL}/tickets/${filtro}/${valor}`);
	}
	
	crear_ticket(payload){
		return axios.post(`${baseURL}/tickets/`, payload);
	}
	
	editar_ticket(id, payload){
		return axios.put(`${baseURL}/tickets/${id}`, payload);
	}

	/** Documentos - ticket */
	cargar_documento_tickets(id, payload){
		return axios.post(`${baseURL}/documentos/${id}`, payload);
	}

	listar_documento_tickets(id){
		return axios.get(`${baseURL}/documentos/${id}`);
	}

	descarga_documento_tickets(id){
		return axios.get(`${baseURL}/documentos/descarga/${id}`, {responseType: 'blob'});
	}

	eliminar_documento_tickets(id){
		return axios.delete(`${baseURL}/documentos/${id}`);
	}

	/** Mensajes - ticket */
	listar_comentarios_tickets(filtro, valor){
		return axios.get(`${baseURL}/comentarios/${filtro}/${valor}`);
	}

	enviar_comentario_tickets(id, payload){
		return axios.post(`${baseURL}/comentarios/${id}`,payload);
	}

	leer_comentario_tickets(ticket_id){
		return axios.put(`${baseURL}/comentarios/${ticket_id}`);
	}

	/** Prospectos */
	listar_prospectos() {
		return axios.get(`${baseURL}/prospects`)
	}

	ver_prospecto(prospecto_id) {
		return axios.get(`${baseURL}/prospects/${prospecto_id}`);
	}
}

export default new Clientes();